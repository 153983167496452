import { BLACK, Logo, IconLinkMenu } from "@120wateraudit/waterworks";

import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { ProfileBadge, useCurrentUser } from "src/modules/User";
import { getDefaultAccount } from "../User/util";
import { ONETWENTY_WATER_ENVIRONMENT } from "src/constants";
import { useConfig, Flags } from "src/hooks/useConfig";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

const Navigation: React.FC = () => {
  const { isLoading, user } = useCurrentUser();
  const [hasStateDashboardMap] = useConfig(Flags.StateDashboardMap);

  const account = getDefaultAccount(user);
  const showNavigationLinks = !isLoading && account;
  return (
    <Nav>
      <NavLink
        style={{
          paddingRight: "10px",
          marginRight: "6px",
          fontWeight: "bold",
          borderRight: "1px solid rgba(0,0,0,0.25)",
        }}
        to={"/"}
      >
        <Logo
          logoUrl={account?.logoUrl}
          environment={ONETWENTY_WATER_ENVIRONMENT}
        />
        {isLoading ? null : account ? account.name : "State Dashboard"}
      </NavLink>
      {showNavigationLinks && (
        <>
          <>
            {hasStateDashboardMap && (
              <NavLink to={"/insights"}>Insights</NavLink>
            )}
          </>
          <NavLink to={"/utilities"}>Utilities</NavLink>
          <NavLink to={"/submissions"}>Submissions</NavLink>
        </>
      )}

      {!isLoading && (
        <ProfileContainer>
          <IconLinkMenu
            icon={faQuestionCircle}
            size="lg"
            menuItems={[
              {
                url: "https://pws-hc.120water.com/state-dashboard",
                label: "Help Center",
              },
              {
                url: "https://120water.com/support/",
                label: "Contact Support",
              },
            ]}
          />
          <NavLink to={"/accountSettings"}>
            <FontAwesomeIcon
              icon={faGear}
              size="lg"
              style={{ paddingRight: "10px" }}
            />
          </NavLink>
          <ProfileBadge />
        </ProfileContainer>
      )}
    </Nav>
  );
};

const Nav = styled.nav`
  grid-area: header;
  min-height: 56px;
  max-height: 72px;
  background: white;
  display: flex;
  align-items: center;
  padding: 0 5vw;
  gap: 8px;

  & > a {
    text-decoration: none;
    color: ${BLACK};
    font-weight: bold;

    &:hover {
      color: #0891b2;
      text-decoration: underline;
    }

    &.active {
      color: #0891b2;
      text-decoration: underline;
    }

    &:first-child {
      display: flex;
      align-items: center;
      font-size: 15px;
      gap: 8px;
    }
  }
`;

const ProfileContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;

  svg {
    color: black;
    :hover {
      color: #4183c4;
    }
  }
`;

export default Navigation;
