import React from "react";
import { Modal } from "semantic-ui-react";
import { FlatButton } from "@120wateraudit/waterworks";
import styled from "styled-components";
import { toastSuccess, toastError } from "src/utils/toast";
import {
  useCreateSubmissionCommunicationMutation,
  useUpdateSubmissionCommunicationMutation,
} from "src/services/state-submission";
import { SubmissionCommunicationStatus } from "src/types/enums/SubmissionCommunicationStatus";
import { getColorFromCommunicationStatus } from "./SubmissionCommunicationStatusBadge";
import Loader from "src/components/Loader";

interface Props {
  value: string;
  pwsId: number;
  submissionPeriodId: number;
  pwsName: string;
  submissionCommunicationId: number;
  refetch: () => void;
  closeModal: () => void;
}

const getConsumerNoticeVerificationText = (
  status: SubmissionCommunicationStatus
): JSX.Element => {
  switch (status) {
    case SubmissionCommunicationStatus.accepted:
      return (
        <h4>
          By making this change, you are verifying that you <br />
          have recived a Customer Notice Form from this PWS
        </h4>
      );
    case SubmissionCommunicationStatus.rejected:
      return (
        <h4>
          By making this change, this user will have to re-
          <br />
          submit their Customer Notice Form on the platform
        </h4>
      );
    default:
      return <h4>Are you sure you want to change the status?</h4>;
  }
};

const ChangeConsumerNoticeModal = ({
  value,
  pwsId,
  submissionPeriodId,
  pwsName,
  submissionCommunicationId,
  refetch,
  closeModal,
}: Props): JSX.Element => {
  const [updateSubmissionCommunicationStatus, { isLoading: isLoadingUpdate }] =
    useUpdateSubmissionCommunicationMutation();
  const [createPWSSubmissionCommunication, { isLoading: isLoadingCreate }] =
    useCreateSubmissionCommunicationMutation();

  const onUpdateStatus = async (): Promise<void> => {
    try {
      if (submissionCommunicationId) {
        await updateSubmissionCommunicationStatus({
          submissionCommunicationId,
          value,
        }).unwrap();
      } else {
        await createPWSSubmissionCommunication({
          pwsId,
          submissionPeriodId,
          value,
        }).unwrap();
      }

      refetch();
      closeModal();
      toastSuccess("Consumer Notice Status updated successfully.");
    } catch (error: unknown) {
      console.error(error);
      toastError("An error occurred, please try again.");
    }
  };

  const handleUpdateStatus = (): void => {
    onUpdateStatus().catch((error) => {
      console.error("Failed to update status:", error);
    });
  };

  return (
    <ChangeStatusContainer>
      <StyledModalContent>
        <h3>
          {`You are about to change PWS ${pwsName} Customer Notice`} Submission
          Status to{" "}
          <ValueContainer
            color={getColorFromCommunicationStatus(
              value as SubmissionCommunicationStatus
            )}
          >
            {`'${value}'`}
          </ValueContainer>
        </h3>

        <Modal.Description>
          <h4>
            {getConsumerNoticeVerificationText(
              value as SubmissionCommunicationStatus
            )}
          </h4>
        </Modal.Description>
      </StyledModalContent>
      <StyledModalActions>
        {isLoadingUpdate || isLoadingCreate
        ? (
          <Loader />
        )
        : (
          <FlatButton onClick={handleUpdateStatus} variant="primary">
            Change Consumer Notice Status
          </FlatButton>
        )}
      </StyledModalActions>
    </ChangeStatusContainer>
  );
};

const ChangeStatusContainer = styled.div`
  margin: 40px 0px;
`;

const StyledModalContent = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h3 {
    max-width: 555px;
  }
`;

const StyledModalActions = styled(Modal.Actions)`
  margin: 20px 0;
  text-align: center;
`;

const ValueContainer = styled.span`
  color: ${({ color }) => color};
`;

export default ChangeConsumerNoticeModal;
