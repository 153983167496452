import {
  AQUA_BLUE,
  LIGHTEST_GRAY,
  TERRA_GREEN,
} from "@120wateraudit/waterworks";
import React from "react";
import { Dropdown } from "semantic-ui-react";
import { SubmissionCommunicationStatus } from "src/types/enums/SubmissionCommunicationStatus";
import styled from "styled-components";
import { useTooltip } from "../Tooltip/Tooltip";
import { PwsSubmissionPeriod } from "src/services";

export const getColorFromCommunicationStatus = (
  status: SubmissionCommunicationStatus
): string => {
  switch (status) {
    case SubmissionCommunicationStatus.submitted:
      return AQUA_BLUE;
    case SubmissionCommunicationStatus.accepted:
      return TERRA_GREEN;
    case SubmissionCommunicationStatus.rejected:
      return "#FDE68A";
    case SubmissionCommunicationStatus.notCompleted:
      return "#D3D3D3";
    default:
      return LIGHTEST_GRAY;
  }
};

interface StatusBadgeProps {
  pwsSubmissionPeriodTableData: PwsSubmissionPeriod;
  openChangeSubmissionCommunicationStatus: any;
  refetch: () => Promise<any>;
}

const SubmissionCommunicationStatusBadge: React.FC<StatusBadgeProps> = ({
  pwsSubmissionPeriodTableData,
  openChangeSubmissionCommunicationStatus,
  refetch,
}) => {
  const { submissionCommunication, pwsName, pwsId, submissionPeriodId } = pwsSubmissionPeriodTableData;

  const data = submissionCommunication ?? {
    status: SubmissionCommunicationStatus.notCompleted,
  };

  const { setTooltipContent, setTooltipPosition } = useTooltip();

  const handleChange = (
    _: React.SyntheticEvent<HTMLElement>,
    { value }: any
  ): void => {
    openChangeSubmissionCommunicationStatus(value, pwsId, submissionPeriodId, pwsName, data.id, refetch);
  };

  const statusOptions = Object.values(SubmissionCommunicationStatus)
    .map((status) => ({
      key: status,
      text: status,
      value: status,
    }))
    .filter(
      ({ value }) =>
        value === SubmissionCommunicationStatus.accepted ||
        value === SubmissionCommunicationStatus.rejected ||
        value === data.status
    );

  return (
    <StyledDropdown
      onMouseEnter={(e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const rect = e.currentTarget.getBoundingClientRect();
        setTooltipPosition(rect.x + rect.width, rect.y + rect.height - 90);
        setTooltipContent(
          <span>Change the user&apos;s Customer Notice Submission Status</span>
        );
      }}
      onMouseLeave={() => setTooltipContent(null)}
      value={data.status}
      options={statusOptions}
      onChange={handleChange}
      selection
      style={dropdownStyle(data.status)}
    />
  );
};

const dropdownStyle = (status: SubmissionCommunicationStatus): Object => ({
  backgroundColor: getColorFromCommunicationStatus(status),
  borderRadius: "5px",
  minWidth: "150px",
  fontWeight: "500",
  padding: "3px",
  minHeight: "unset",
  textAlign: "center",
});

const StyledDropdown = styled(Dropdown)<{ value: string }>`
  min-width: 120px;
  text-align: center;
  background-color: ${({ value }) =>
    getColorFromCommunicationStatus(value as SubmissionCommunicationStatus)};
  color: white;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  .dropdown.icon {
    top: 2px !important;
  }
`;

export default SubmissionCommunicationStatusBadge;
