import React, { useCallback, useEffect, useRef } from "react";
import { Checkbox, Form, Input } from "semantic-ui-react";
import styled from "styled-components";
import { RadioCard } from "./RadioCard";
import {
  Form as FinalForm,
  Field,
  FieldRenderProps,
  useForm,
} from "react-final-form";
import {
  TenantConfig,
  useGetTenantConfigQuery,
  usePutTenantConfigMutation,
} from "src/services/ptd";
import Loader from "src/components/Loader";
import { Flags, useConfig } from "src/hooks/useConfig";
import { toastError, toastSuccess } from "src/utils/toast";
import { PtdCustomTextArea } from "./PrefilledTextArea";
import { AQUA_BLUE, Button } from "@120wateraudit/waterworks";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: calc(100% + 50px);
  margin: -25px;

  > * {
    padding: 25px;
  }

  > :not(:first-child) {
    border-top: 1px solid #e0e0e0;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledSettingsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 39px;
`;

const SettingsH2 = styled.h2`
  margin: 0;
  font-size: 24px;
`;

const SettingTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
`;

const StyledTextButton = styled.button`
  padding: 10px;
  color: #0891b2;
  font-size: 15px;
  font-weight: 600;
  line-height: 19.85px;
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
`;

const StyledFieldContainer = styled.div<{ disabled?: boolean }>`
  width: 100%;
  max-width: 815px;
`;

const StyledSaveButton = styled(Button)`
  width: 145px;
  height: 40px;
`;

const StyledFormError = styled.span`
  color: red;
  float: right;
  padding-right: 10px;
`;

const StyledLinkButton = styled.button`
  padding: 0;
  color: ${AQUA_BLUE};
  font-weight: 400;
  background: none;
  border: none;
  cursor: pointer;
`;

interface SubmitSettings {
  active: boolean;
  title?: string;
  disclaimer?: string;
  infoPanel?: string;
  systemOwnedMaterial?: string;
  customerOwnedMaterial?: string;
  allowUtilityLinks: boolean;
  logoBase64?: string;
}

function getPreviewUrl(pageUrl: string): string {
  const parts = pageUrl.split(".");
  parts[0] = `preview-${parts[0]}`;
  let url = parts.join(".");
  !url.startsWith("http") && (url = `https://${url}`);
  url = url.replace(/\/$/, "");
  return url;
}

export const DisplaySettingsFlagged: React.FC = () => {
  const [statePtdEnabled, loaded] = useConfig(Flags.StatePtdSettings);
  if (!loaded) {
    return (
      <PageContainer>
        <Loader />
      </PageContainer>
    );
  }

  if (statePtdEnabled) {
    return <DisplaySettings />;
  }

  return null;
};

export const PreviewListener: React.FC<{
  config: TenantConfig;
  proxy: WindowProxy | null;
}> = ({ config, proxy }) => {
  const form = useForm();
  useEffect(() => {
    if (!config.pageUrl) {
      return;
    }
    const url = getPreviewUrl(config.pageUrl);
    const handleReceiveMessage = (event: MessageEvent): void => {
      if (event.origin !== url || !proxy) {
        return;
      }
      const state = form.getState().values;
      const data = JSON.parse(event.data);
      if (data) {
        const disclaimer = `${config.tenantType.disclaimer ?? ""}${
          config.tenantType.disclaimer ? " " : ""
        }${(state.disclaimer as string) ?? ""}`;
        proxy.postMessage(
          {
            title: state.title,
            info: state.infoPanel,
            logo: state.logoBase64,
            mapCode: config.mapCode,
            allowUtilityLinks: state.allowUtilityLinks,
            disclaimer,
          },
          url
        );
      }
    };
    window.addEventListener("message", handleReceiveMessage);
    return () => {
      window.removeEventListener("message", handleReceiveMessage);
    };
  }, [config, proxy]);
  return null;
};

export const DisplaySettings: React.FC = () => {
  const imgInputRef = useRef<HTMLInputElement>(null);

  const [isActive, setIsActive] = React.useState(false);
  const [proxy, setProxy] = React.useState<WindowProxy | null>(null);

  const {
    data: config,
    isLoading,
    error,
    refetch,
  } = useGetTenantConfigQuery(null);

  const [trigger, { isLoading: isMutating }] = usePutTenantConfigMutation();

  useEffect(() => {
    if (!config) {
      return;
    }
    setIsActive(config.active);
  }, [config]);

  const onSubmit: (data: SubmitSettings) => Promise<any> = async (data) => {
    if (!config) {
      return;
    }
    try {
      await trigger({
        ...config,
        ...data,
      }).unwrap();
      toastSuccess("Successfully updated settings");
      try {
        await refetch().unwrap();
      } catch {
        toastError("An error occured refreshing the data");
      }
    } catch {
      toastError("An error occurred during update");
    }
  };

  const preview = useCallback(
    (config: TenantConfig): void => {
      if (!config.pageUrl) {
        return;
      }
      let url = getPreviewUrl(config.pageUrl);
      url = `${url}/?loc=${window.location.origin}`;
      const proxy = window.open(url, "_blank");
      setProxy(proxy);
    },
    [config]
  );

  return (
    <PageContainer>
      {isLoading && <Loader />}
      {!isLoading && !error && config && (
        <>
          <FinalForm
            onSubmit={onSubmit}
            render={({ handleSubmit, valid }) => (
              <>
                <PreviewListener config={config} proxy={proxy} />
                <StyledForm onSubmit={handleSubmit}>
                  <StyledSettingsHeader>
                    <SettingsH2>State PTD Settings</SettingsH2>
                    <StyledSaveButton
                      variant="primary"
                      type="submit"
                      onClick={() => {
                        if (!valid) {
                          toastError("Some fields are invalid");
                        }
                      }}
                      disabled={isMutating}
                    >
                      {isMutating ? "Saving..." : "Save"}
                    </StyledSaveButton>
                  </StyledSettingsHeader>
                  <StyledFieldContainer>
                    <SettingTitle>PTD Active / Inactive</SettingTitle>
                    <Field<boolean> name="active" initialValue={config.active}>
                      {({ input }: FieldRenderProps<boolean>) => {
                        const val = input.value ? "true" : "false";
                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 20,
                            }}
                          >
                            <Checkbox
                              defaultChecked={input.value}
                              value={val}
                              onChange={() => {
                                input.onChange(!input.value);
                                setIsActive(!isActive);
                              }}
                              toggle
                            />
                            <StyledLinkButton
                              type="button"
                              onClick={() => preview(config)}
                            >
                              Preview
                            </StyledLinkButton>
                          </div>
                        );
                      }}
                    </Field>
                  </StyledFieldContainer>
                  <StyledFieldContainer>
                    <SettingTitle>Title Header</SettingTitle>
                    <Field<string>
                      name="title"
                      initialValue={config?.title}
                      parse={(value) => value}
                    >
                      {({ input }: FieldRenderProps<string>) => (
                        <Input
                          name={input.name}
                          value={input.value}
                          onChange={input.onChange}
                          placeholder="Enter a public-facing headline for your dashboard"
                          fluid
                        />
                      )}
                    </Field>
                  </StyledFieldContainer>
                  <StyledFieldContainer>
                    <SettingTitle>Page URL</SettingTitle>
                    <a
                      href={`${
                        config.pageUrl && !config.pageUrl.startsWith("http")
                          ? "https://"
                          : ""
                      }${config.pageUrl ?? ""}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {config?.pageUrl}
                    </a>
                  </StyledFieldContainer>
                  <StyledFieldContainer>
                    <SettingTitle>Logo</SettingTitle>
                    <Field<string>
                      name="logoBase64"
                      initialValue={config?.logoBase64}
                      parse={(value) => value}
                    >
                      {({ input }: FieldRenderProps<string>) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            width: "fit-content",
                          }}
                        >
                          <div
                            style={{
                              width: 89,
                              height: 89,
                              backgroundColor: "lightgrey",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 8,
                            }}
                          >
                            {input.value && input.value.length > 0 && (
                              <img
                                src={input.value}
                                alt="logo"
                                width="72px"
                                height="72px"
                                style={{
                                  borderRadius: 8,
                                  objectFit: "contain",
                                }}
                              />
                            )}
                          </div>
                          <StyledTextButton
                            onClick={() => imgInputRef.current?.click()}
                            type="button"
                          >
                            Change
                          </StyledTextButton>
                          <input
                            type="file"
                            ref={imgInputRef}
                            onChange={(e) => {
                              e.preventDefault();
                              if (!e.target.files) {
                                return;
                              }
                              const file = e.target.files[0];
                              const reader = new FileReader();
                              reader.onload = (readerEvent) => {
                                if (!reader.result) {
                                  return;
                                }
                                if (file.size <= 15000) {
                                  input.onChange(reader.result as string);
                                  return;
                                }
                                const img = new Image();
                                img.onload = () => {
                                  const canvas =
                                    document.createElement("canvas");
                                  const ctx = canvas.getContext("2d");
                                  if (!ctx) {
                                    toastError(
                                      "Could not load image. Please try again"
                                    );
                                    return;
                                  }

                                  const maxWidth = 72;
                                  const maxHeight = 72;

                                  let width = img.width;
                                  let height = img.height;

                                  if (width > height) {
                                    if (width > maxWidth) {
                                      height *= maxWidth / width;
                                      width = maxWidth;
                                    }
                                  } else {
                                    if (height > maxHeight) {
                                      width *= maxHeight / height;
                                      height = maxHeight;
                                    }
                                  }

                                  canvas.width = width;
                                  canvas.height = height;

                                  ctx.drawImage(img, 0, 0, width, height);
                                  const dataUrl = canvas.toDataURL(
                                    "image/jpeg",
                                    1
                                  );
                                  input.onChange(dataUrl);
                                  const fileSizeInBytes = Math.round(
                                    (dataUrl.length * 3) / 4
                                  );
                                  const fileSizeInKB = (
                                    fileSizeInBytes / 1024
                                  ).toFixed(2);
                                  console.log(
                                    `New image file size: ${fileSizeInKB} KB`
                                  );
                                };
                                img.src = (readerEvent.target as any).result;
                              };
                              reader.readAsDataURL(file);
                            }}
                            hidden
                          />
                        </div>
                      )}
                    </Field>
                  </StyledFieldContainer>
                  <StyledFieldContainer>
                    <SettingTitle>Disclaimer</SettingTitle>
                    <Field<string>
                      name="disclaimer"
                      initialValue={config.disclaimer}
                      validate={(value) => {
                        if (value && value.length > 6000) {
                          return "Disclaimer cannot be more than 6000 characters";
                        }
                      }}
                      parse={(value) => value}
                    >
                      {({
                        input,
                        meta,
                      }: FieldRenderProps<string, HTMLElement>) => (
                        <>
                          <PtdCustomTextArea
                            text={config.tenantType.disclaimer ?? ""}
                            name={input.name}
                            value={input.value}
                            onChange={input.onChange}
                            maxCharacters={6000}
                          />
                          {meta.error && meta.touched && (
                            <StyledFormError>{meta.error}</StyledFormError>
                          )}
                        </>
                      )}
                    </Field>
                  </StyledFieldContainer>
                  <StyledFieldContainer>
                    <SettingTitle>Info Panel</SettingTitle>
                    <Field<string>
                      name="infoPanel"
                      initialValue={config.infoPanel}
                      validate={(value) => {
                        if (value && value.length > 6000) {
                          return "Info Panel cannot be more than 6000 characters";
                        }
                      }}
                      parse={(value) => value}
                    >
                      {({
                        input,
                        meta,
                      }: FieldRenderProps<string, HTMLElement>) => (
                        <>
                          <PtdCustomTextArea
                            name={input.name}
                            value={input.value}
                            onChange={input.onChange}
                            maxCharacters={6000}
                          />
                          {meta.error && meta.touched && (
                            <StyledFormError>{meta.error}</StyledFormError>
                          )}
                        </>
                      )}
                    </Field>
                  </StyledFieldContainer>
                </StyledForm>
                <StyledForm onSubmit={handleSubmit}>
                  <StyledSettingsHeader>
                    <SettingsH2>Rename Service Lines</SettingsH2>
                    <StyledSaveButton
                      variant="primary"
                      type="submit"
                      onClick={() => {
                        if (!valid) {
                          toastError("Some fields are invalid");
                        }
                      }}
                      disabled={isMutating}
                    >
                      {isMutating ? "Saving..." : "Save"}
                    </StyledSaveButton>
                  </StyledSettingsHeader>
                  <StyledFieldContainer>
                    <SettingTitle>System-Owned Material</SettingTitle>
                    <Field<string>
                      name="systemOwnedMaterial"
                      initialValue={config.systemOwnedMaterial}
                      parse={(value) => value}
                    >
                      {({ input }: FieldRenderProps<string, HTMLElement>) => (
                        <Input
                          name={input.name}
                          value={input.value}
                          onChange={input.onChange}
                          fluid
                        />
                      )}
                    </Field>
                  </StyledFieldContainer>
                  <StyledFieldContainer>
                    <SettingTitle>Customer-Owned Material</SettingTitle>
                    <Field<string>
                      name="customerOwnedMaterial"
                      initialValue={config.customerOwnedMaterial}
                      parse={(value) => value}
                    >
                      {({ input }: FieldRenderProps<string, HTMLElement>) => (
                        <Input
                          name={input.name}
                          value={input.value}
                          onChange={input.onChange}
                          fluid
                        />
                      )}
                    </Field>
                  </StyledFieldContainer>
                </StyledForm>
                <StyledForm onSubmit={handleSubmit}>
                  <StyledSettingsHeader>
                    <SettingsH2>Utility PTD Links</SettingsH2>
                    <StyledSaveButton
                      variant="primary"
                      type="submit"
                      onClick={() => {
                        if (!valid) {
                          toastError("Some fields are invalid");
                        }
                      }}
                      disabled={isMutating}
                    >
                      {isMutating ? "Saving..." : "Save"}
                    </StyledSaveButton>
                  </StyledSettingsHeader>
                  <StyledFieldContainer>
                    <SettingTitle>
                      Allow the utility to provide a clickable link for the
                      public to access?
                    </SettingTitle>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: 40,
                      }}
                    >
                      <Field<boolean>
                        name="allowUtilityLinks"
                        initialValue={config.allowUtilityLinks}
                      >
                        {({
                          input,
                        }: FieldRenderProps<boolean, HTMLElement>) => (
                          <>
                            <RadioCard
                              title={"Allow"}
                              description={
                                "This allows the public to access the Utilities's clickable link from the State PTD"
                              }
                              selected={input.value}
                              onClick={() => {
                                input.onChange(!input.value);
                              }}
                            />
                            <RadioCard
                              title={"Don't Allow"}
                              description={
                                "This prevents the utility from sharing a clickable link from State PTD."
                              }
                              selected={!input.value}
                              onClick={() => {
                                input.onChange(!input.value);
                              }}
                            />
                          </>
                        )}
                      </Field>
                    </div>
                  </StyledFieldContainer>
                </StyledForm>
              </>
            )}
          />
        </>
      )}
    </PageContainer>
  );
};
