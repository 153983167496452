export const trueFalseListOptions = [
        {
                id: 'true',
                text: 'Yes',
                value: "true"
        },
        {
                id: 'false',
                text: 'No',
                value: "false"
        }
]
