import { User, useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useCurrentUser } from "src/modules/User";
import LoginErrorPage from "src/pages/Auth0Error";
import { MediaContextProvider } from "./Media";
import Routes from "./Routes";
import "./App.css";
import { Flags, useConfigAsync } from "src/hooks/useConfig";

if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID ?? "");
}

const App: React.FC = () => {
  const {
    error,
    isAuthenticated,
    isLoading: isAuthLoading,
    loginWithRedirect,
  } = useAuth0();
  const { user, isLoading: isUserLoading } = useCurrentUser();
  const [showDashboard, setShowDashboard] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    const fetchConfig = async (user: User): Promise<void> => {
      const data = await useConfigAsync(user, Flags.StateDashboardMap);
      setShowDashboard(data);
    };
    if (!isUserLoading && user) {
      void fetchConfig(user);
    }
  }, [isUserLoading, user]);

  if (!isAuthenticated && !isAuthLoading && error != null) {
    // Something went wrong with authorizing with Auth0.
    // We need to drop the user on a page where they can see the error and try again.
    return <LoginErrorPage />;
  }

  if (!isAuthLoading && !isAuthenticated) {
    void loginWithRedirect();
  }

  // We show the loading screen if we're still fetching any part of the user or
  // if Auth0 isn't authenticated.
  const isLoading = isAuthLoading || isUserLoading || !isAuthenticated || showDashboard === undefined;
  return (
    <MediaContextProvider>
      <Routes isLoading={isLoading} hasStateDashboardMap={showDashboard} />
    </MediaContextProvider>
  );
};

export default App;
